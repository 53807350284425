// =========================================================
// * Vue Material Dashboard Laravel - v1.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard-laravel
// * Copyright Creative Tim (https://www.creative-tim.com) & UPDIVISION (https://www.updivision.com)
//
// * Coded by www.creative-tim.com & www.updivision.com
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import axios from 'axios'

// Plugins
import App from './App.vue'
import Chartist from 'chartist'
import VueAxios from 'vue-axios'
import { VueMaterial } from 'vue-material'
import DashboardPlugin from './material-dashboard'
import moment from 'moment'

moment.locale('pt-br', {
  months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
})

// plugin setup
Vue.use(DashboardPlugin)
Vue.use(VueAxios, axios)

Vue.filter('MonthYearFormatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/YYYY')
  }
})

Vue.filter('MonthYearFormatDateWithName', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM [de] YYYY')
  }
})

Vue.filter('DateFormat', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('DateTimeFormat', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY h:mm')
  }
})

Vue.filter('ValueFormat', function(value) {
  if (value || value == 0) {
    return value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }
})

Vue.filter('CurrencyFormat', function(value) {
  if (value || value == 0) {
    return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }
})

// router & store setup
import router from './router'
import store from './store'

// global library setup
Vue.prototype.$Chartist = Chartist

/* eslint-disable no-new */
const app = new Vue({
  router: router,
  store: store,
  el: '#app',
  render: h => h(App),
})

store.$app = app
