<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form novalidate @submit.prevent="register">
        <signup-card>
          <h2 class="title text-center" slot="title">Faça seu cadastro!</h2>
          <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 ml-auto" slot="content-left">
            <div class="info info-horizontal" v-for="item in contentLeft" :key="item.title">
              <div :class="`icon ${item.colorIcon}`">
                <md-icon>{{ item.icon }}</md-icon>
              </div>
              <div class="description">
                <h4 class="info-title">{{ item.title }}</h4>
                <p class="description">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </div>
          <div class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100 mr-auto" slot="content-right">
            <md-field class="md-form-group md-invalid" :class="getValidationClass('full_name')" style="margin-bottom: 2rem">
              <md-icon>face</md-icon>
              <label>NOME COMPLETO</label>
              <md-input v-model="full_name" />
              <span class="md-error" v-if="!$v.full_name.required && !firstLoad">Este campo é obrigatório</span>
              <span class="md-error" v-else-if="!$v.full_name.minLength && !firstLoad">Insira um nome válido</span>
              <validation-error :error="apiValidationErrors.full_name" />
            </md-field>

            <md-field :class="getValidationClass('email')" style="margin-bottom: 2rem">
              <md-icon>email</md-icon>
              <label>E-MAIL</label>
              <md-input v-model="email" />
              <span class="md-error" v-if="apiValidationErrors.email">{{ apiValidationErrors.email.detail }}</span>
              <validation-error :error="apiValidationErrors.email" /><br />
              <span class="md-error" v-if="!$v.email.required && !firstLoad">Este campo é obrigatório</span>
              <span class="md-error" v-else-if="!$v.email.email && !firstLoad">Insira um e-mail válido</span>
            </md-field>

            <md-field :class="getValidationClass('cpfCnpj')" class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>badge</md-icon>
              <label>CPF / CNPJ</label>
              <md-input id="cpfCnpj" v-model="cpfCnpj" />
              <validation-error :error="apiValidationErrors.cpf_cnpj" />
              <span class="md-error" v-if="!$v.cpfCnpj.required && !firstLoad">Este campo é obrigatório</span>
              <span class="md-error" v-else-if="(!$v.cpfCnpj.minLength || !$v.cpfCnpj.maxLength) && !firstLoad">CNPJ ou CPF inválido</span>
            </md-field>

            <md-field :class="getValidationClass('gender')" class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>account_circle</md-icon>
              <label>GÊNERO</label>
              <md-select v-model="gender" name="gender">
                <md-option value="M">MASCULINO</md-option>
                <md-option value="F">FEMINIMO</md-option>
                <md-option value="O">OUTROS</md-option>
              </md-select>
              <span class="md-error" v-if="!$v.gender.required && !firstLoad">Este campo é obrigatório</span>
              <validation-error :error="apiValidationErrors.gender" />
            </md-field>

            <md-field :class="getValidationClass('password')" class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>lock_outline</md-icon>
              <label>SENHA</label>
              <md-input @blur="validateFields" v-model="password" type="password" />
              <span class="md-error" v-if="!$v.password.required && !errors.password && !firstLoad">Este campo é obrigatório</span>
              <span class="md-error" v-else-if="!$v.password.minLength && !errors.password && !firstLoad">A senha deve conter no minimo 6 digitos</span>
              <validation-error :error="apiValidationErrors.password" />
            </md-field>

            <md-field class="md-form-group md-invalid">
              <md-icon>lock_outline</md-icon>
              <label>CONFIRME SUA SENHA</label>
              <md-input @blur="validateFields" v-model="confirmation_password" type="password" />
              <validation-error :error="apiValidationErrors.confirmation_password" />
            </md-field>
            <md-field>
              <md-input type="hidden" v-model="referral_code" disabled />
            </md-field>
            <md-field v-if="referral_code && referral_name" class="md-form-group md-invalid" style="margin-bottom: 2rem">
              <md-icon>co_present</md-icon>
              <label>INDICADO POR:</label>
              <md-input v-model="referral_name" disabled />
            </md-field>
            <div style="display: flex">
              <md-switch style="margin-right: 1%" v-model="terms_conditions">Eu aceito os </md-switch
              ><a style="margin-top: 3.5%; font-weight: bold" href="https://apurebolsa.com.br/painel/terms.html" target="_blank">termos e condições</a>.
            </div>
            <div style="display: flex">
              <md-switch style="margin-right: 1%" v-model="privacy">Eu aceito as </md-switch
              ><a style="margin-top: 3.5%; font-weight: bold" href="https://apurebolsa.com.br/painel/privacy.html#" target="_blank">politicas de privacidade</a
              >.
            </div>
            <validation-error :error="apiValidationErrors.terms" />
            <div class="button-container">
              <md-button class="md-success md-round mt-4" type="submit" slot="footer"> Cadastrar </md-button>
            </div>
          </div>
        </signup-card>
      </form>
    </div>
  </div>
</template>
<script>
import { SignupCard, ValidationError } from '@/components'
import formMixin from '@/mixins/form-mixin'
import Swal from 'sweetalert2'

import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  components: {
    SignupCard,
    ValidationError,
  },
  mixins: [formMixin],
  data() {
    return {
      full_name: null,
      firstLoad: true,
      terms_conditions: false,
      privacy: false,
      email: null,
      gender: null,
      cpfCnpj: null,
      password: null,
      confirmation_password: null,
      referral_code: null,
      referral_name: null,
      validForm: false,
      errors: {},
      contentLeft: [
        {
          colorIcon: 'icon-success',
          icon: 'timeline',
          title: 'Pare de quebrar a cabeça!',
          description:
            'Com a Apure Bolsa é só você enviar suas notas de corretagem e extratos disponibilizados pelas corretoras, no nosso whatsapp, o que levará apenas alguns minutos, e depois é só receber de volta, no seu whatsapp ou e-mail, relatórios completos e de fácil entendimento, no padrão da Receita Federal, sobre suas movimentações na Bolsa. ',
        },
      ],
    }
  },
  validations: {
    full_name: {
      required,
      minLength: minLength(3),
    },
    password: {
      required,
      minLength: minLength(6),
    },
    gender: {
      required,
    },
    cpfCnpj: {
      required,
      minLength: minLength(14),
      maxLength: maxLength(19),
    },
    email: {
      required,
      email,
    },
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v[fieldName]
      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        }
      }
    },
    async getReferralName() {
      const requestOptions = {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      }
      const referral = {
        code: this.referral_code,
      }
      this.referral_name = await this.$store.dispatch('getReferralName', { referral, requestOptions })
    },
    async validateFields() {
      this.errors = {}
      const regexPassword = /^(?=.*[0-9])(?=.*[a-zA-Z]).*$/

      if (!regexPassword.test(this.password)) this.errors.password = ['A senha precisa ter ao menos 1 letra e um 1 número']

      if (this.password !== this.confirmation_password) this.errors.confirmation_password = ['As senhas não coincidem']

      if (Object.keys(this.errors).length === 0) this.validForm = true
      else await this.$store.dispatch('alerts/error', 'Verifique os campos ')
    },
    async register() {
      var this_ori = this;
      this_ori.firstLoad = false
      this_ori.$v.$touch()
      this_ori.validateFields()
      if (!this_ori.validForm || this_ori.$v.$invalid) return

      if (!this_ori.terms_conditions || !this_ori.privacy) {
        await this_ori.$store.dispatch('alerts/error', 'Você precisa aceitar os termos e condições.')
        return
      }

      const user = {
        full_name: this_ori.full_name,
        email: this_ori.email,
        gender: this_ori.gender,
        cpf_cnpj: this_ori.cpfCnpj,
        password: this_ori.password,
        confirmation_password: this_ori.confirmation_password,
        referral_code: this_ori.referral_code,
        terms_conditions: this_ori.terms_conditions,
        privacy: this_ori.privacy,
      }

      const requestOptions = {
        headers: {
          Accept: 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
        },
      }
      try {
        Swal.showLoading()
        await this_ori.$store.dispatch('send_code', { user, requestOptions })
      } catch (e) {
        this_ori.setApiValidation(e.response.data);
        console.log(this_ori.apiValidationErrors)
        await this_ori.$store.dispatch('alerts/error', 'Erro ao enviar dados, tente novamente.')
      } finally {
        Swal.close()
      }
    },
  },
  mounted() {
    this.referral_code = this.$route.query.referralCode
    if (this.referral_code) {
      this.getReferralName()
    }

    $(document).ready(function ($) {
      var options = {
        onKeyPress: function (cpf, ev, el, op) {
          var masks = ['000.000.000-000', '00.000.000/0000-00']
          $('#cpfCnpj').mask(cpf.length > 14 ? masks[1] : masks[0], op)
        },
      }

      $('#cpfCnpj').length > 11 ? $('#cpfCnpj').mask('00.000.000/0000-00', options) : $('#cpfCnpj').mask('000.000.000-00#', options)
    })
  },
}
</script>
