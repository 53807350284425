// Notifications plugin. Used on Notifications page
import Notifications from '@/components/NotificationPlugin'
// A plugin file where you could register global components used across the app
import GlobalComponents from './globalComponents'
// A plugin file where you could register global directives
import GlobalDirectives from './globalDirectives'
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from './components/SidebarPlugin'
// Tabs plugin. Used on Panels page.

import VueMaterial from 'vue-material'

// asset imports
import 'vue-material/dist/vue-material.min.css'
import './assets/scss/material-dashboard.scss'
import './assets/css/demo.css'

// library auto imports
import 'es6-promise/auto'
import Vuelidate from 'vuelidate'

export default {
  install(Vue) {
    Vue.use(GlobalComponents)
    Vue.use(GlobalDirectives)
    Vue.use(VueMaterial)
    Vue.use(SideBar)
    Vue.use(Notifications)
    Vue.use(Vuelidate)
    Vue.material.locale = {
      // range for datepicker
      startYear: 1900,
      endYear: 2099,

      // date format for date picker
      dateFormat: 'dd/MM/yyyy',

      // i18n strings
      days: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
      shortDays: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      shorterDays: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
      shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      shorterMonths: ['J', 'F', 'M', 'A', 'M', 'Ju', 'Ju', 'A', 'Se', 'O', 'N', 'D'],

      // `0` stand for Sunday, `1` stand for Monday
      firstDayOfAWeek: 1,

      cancel: 'Cancelar',
      confirm: 'Ok',
    }
  },
}
