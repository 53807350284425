import DashboardLayout from '@/pages/Dashboard/Layout/DashboardLayout.vue'
import AuthLayout from '@/pages/Dashboard/Pages/AuthLayout.vue'

// Dashboard pages
import Dashboard from '@/pages/Dashboard/Dashboard.vue'

import Login from '@/pages/Dashboard/Pages/Login.vue'
import LoginPartner from '@/pages/Dashboard/Pages/LoginPartner.vue'
import Register from '@/pages/Dashboard/Pages/Register.vue'
import ConfirmEmail from '@/pages/Dashboard/Pages/ConfirmEmail.vue'
import ForgotPassword from '@/pages/Dashboard/Pages/ForgotPassword.vue'
import ResetPassword from '@/pages/Dashboard/Pages/ResetPassword.vue'

// Components pages
import BrokerageNote from '@/pages/Dashboard/Components/BrokerageNote.vue'
import BrokerageNoteUpload from '@/pages/Dashboard/Components/BrokerageNoteUpload.vue'
import CalculatedIncomeTax from '@/pages/Dashboard/Components/CalculatedIncomeTax.vue'
import Operations from '@/pages/Dashboard/Components/Operations.vue'
import Portfolio from '@/pages/Dashboard/Components/Portfolio.vue'
import PlanSubscription from '@/pages/Dashboard/Components/PlanSubscription.vue'
import ReferralCode from '@/pages/Dashboard/Components/ReferralCode.vue'
import MyIndications from '@/pages/Dashboard/Components/MyIndications.vue'
import Profile from '@/pages/Profile.vue'

//import middleware
import auth from '@/middleware/auth'
import guest from '@/middleware/guest'

let authPages = {
  path: '/',
  component: AuthLayout,
  name: 'Authentication',
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: '/login-partner',
      name: 'LoginPartner',
      component: LoginPartner,
      meta: { middleware: guest },
    },
    {
      path: '/cadastrar',
      name: 'Register',
      component: Register,
      meta: { middleware: guest },
    },
    {
      path: '/confirmar-email',
      name: 'ConfirmEmail',
      component: ConfirmEmail,
      meta: { middleware: guest },
    },
    {
      path: '/esqueceu-senha',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: { middleware: guest },
    },
    {
      path: '/resetar-senha',
      name: 'ResetPassword',
      component: ResetPassword,
      meta: { middleware: guest },
    },
  ],
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    meta: { middleware: auth },
    children: [
      {
        path: '',
        name: 'Dashboard',
        components: { default: Dashboard },
        meta: { middleware: auth },
      },
      {
        path: 'importar-notas',
        name: 'BrokerageNoteUpload',
        components: { default: BrokerageNoteUpload },
        meta: { middleware: auth },
      },
      {
        path: 'ver-notas',
        name: 'BrokerageNote',
        components: { default: BrokerageNote },
        meta: { middleware: auth },
      },
      {
        path: 'apuracoes',
        name: 'CalculatedIncomeTax',
        components: { default: CalculatedIncomeTax },
        meta: { middleware: auth },
      },
      {
        path: 'operacoes',
        name: 'Operations',
        components: { default: Operations },
        meta: { middleware: auth },
      },
      {
        path: 'portfolio',
        name: 'portfolio',
        components: { default: Portfolio },
        // meta: { middleware: auth }
      },
      {
        path: 'assinar-plano',
        name: 'PlanSubscription',
        components: { default: PlanSubscription },
        meta: { middleware: auth },
      },
      {
        path: 'indicacao',
        name: 'ReferralCode',
        components: { default: ReferralCode },
        // meta: { middleware: auth }
      },
      {
        path: 'meus-indicados',
        name: 'MyIndications',
        components: { default: MyIndications },
        // meta: { middleware: auth },
      },
      {
        path: 'minha-conta',
        name: 'Profile',
        components: { default: Profile },
        meta: { middleware: auth },
      },
    ],
  },
  authPages,
]

export default routes
