<template>
  <div>
    <md-card v-if="selectedTab != 'CalculatedIncomeTax'">
      <md-card-content>
        <span>Selecione o mês: </span>
        <md-datepicker v-model="selectedDate" md-immediately />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'TableTemplate',
  data: () => ({

  }),
}
</script>