import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();
const default_result = {
  "totalGain": 0,
  "totalGainPercent": 0,
  "totalItems": 0,
  "sum": 0,
  "items": [],
  "with-error": true
}
var return_result = {};

async function sendOrder(payload){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + payload.token
    }
  }
  return axios.post(`${url}/order/`, payload, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

async function sendPlanOrder(payload){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + payload.token
    }
  }
  return axios.post(`${url}/order/plan/`, payload, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

async function getOrder(payload){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + payload.token
    }
  }
  return axios.get(`${url}/order/check-payment?order_id=`+payload.orderId, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

async function getPendingOrder(payload){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + payload.token
    }
  }
  return axios.get(`${url}/order/check-person-order`, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

export default {
    sendOrder,
    sendPlanOrder,
    getOrder,
    getPendingOrder
};
