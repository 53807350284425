<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <form @submit.prevent="sendForgotPasswordCode">
        <login-card header-color="apure">
          <h4 slot="title" class="title">Digite seu E-mail</h4>
            <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input v-model="email" type="email" />
            <validation-error :error="apiValidationErrors.email" />
          </md-field>
          <md-button
            class="md-success md-round mt-4"
            type="submit"
            slot="footer"
          >
            Confirmar
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Swal from "sweetalert2";

export default {
  components: {
    LoginCard,
    ValidationError
  },

  mixins: [formMixin],
  data() {
    return {
        email: null
    };
  },

  methods: {
    async sendForgotPasswordCode() {
      const user = {
        email: this.email
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };
      try {
        Swal.showLoading();
        await this.$store.dispatch("sendForgotCode", { user, requestOptions });
      } catch (e) {
        this.setApiValidation(e.response.data);
        await this.$store.dispatch("alerts/error", "Verifique os dados e tente novamente!");
      }finally {
        Swal.close();
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
