<template>
    <div v-if="!isLoading">
      <div class="header text-center">
        <h3>{{ profile.full_name }}</h3>
        <p>{{ profile.email }}</p>
        <a href="" @click.prevent="showForm = true" v-show="!isLoading && !showForm">atualizar dados</a>
      </div>
      <div class="md-layout" v-if="showForm">
        <div class="md-layout-item">
          <md-card>
            <md-card-content>
              <form novalidate @submit.prevent="updateProfile">
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field>
                      <label>NOME COMPLETO</label>
                      <md-input v-model="full_name" />
                      <span class="md-error" v-if="!$v.full_name.required">Este campo é obrigatório</span>
                      <span class="md-error" v-else-if="!$v.full_name.minLength">Insira um nome válido</span>
                      <validation-error :error="apiValidationErrors.full_name" />
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                      <label>E-MAIL</label>
                      <md-input v-model="email" disabled/>
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                      <label>TELEFONE</label>
                      <md-input v-model="phone"/>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field>
                      <label>DATA DE NASCIMENTO</label>
                      <md-input v-model="birth_date" />
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                      <label>GÊNERO</label>
                      <md-select v-model="gender" name="gender">
                        <md-option value="M"> MASCULINO</md-option>
                        <md-option value="F"> FEMININO</md-option>
                        <md-option value="O"> OUTROS</md-option>
                      </md-select>
                      <span class="md-error" v-if="!$v.gender.required">Este campo é obrigatório</span>
                      <validation-error :error="apiValidationErrors.gender" />
                    </md-field>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                      <label>CPF/CNPJ</label>
                      <md-input v-model="cpfCnpj" disabled/>
                    </md-field>
                  </div>
                </div>
                <div class="md-layout" style="margin-top: 2rem;">
                  <div class="md-layout-item">
                    <md-button class="md-raised md-primary" type="submit">SALVAR</md-button>
                  </div>
                </div>
              </form>
            </md-card-content>
          </md-card>
        </div>
      </div>
    </div>
  </template>
  <script>
  import formMixin from '@/mixins/form-mixin'
  
  import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
  
  export default {
    mixins: [formMixin],
    data() {
      return {
        full_name: null,
        email: null,
        gender: null,
        cpfCnpj: null,
        birth_date: null,
        showForm: false,
        validForm: false,
        phone: null,
        profile: {},
        loading: true,
        errors: {},
      }
    },
    validations: {
      full_name: {
        required,
        minLength: minLength(3),
      },
      gender: {
        required,
      },
      cpfCnpj: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(19),
      },
      email: {
        required,
        email,
      },
    },
    methods: {
      async getProfile() {
        this.profile = await this.$store.dispatch('profile/me');
        this.full_name = this.profile.full_name;
        this.email = this.profile.email;
        this.gender = this.profile.gender;
        this.cpfCnpj = this.profile.cpf_cnpj;
        this.birth_date = this.profile.birth_date;
        this.loading = false;
      },
      async updateProfile() {
        const profile = {
          full_name: this.full_name,
          gender: this.gender,
          phone: this.phone,
          birth_date: this.birth_date,
        };

        try {
          await this.$store.dispatch("profile/update", JSON.parse(profile));
          await this.$store.dispatch(
            "alerts/success",
            "Dados atualizados com sucesso!"
          );
        } catch (e) {
          await this.$store.dispatch(
            "alerts/error",
            "Oops, algo de errado aconteceu! Por favor, tente novamente."
          );
          this.setApiValidation(e.response.data.errors);
        }
      },
    },
    mounted: function () {
      this.$nextTick(function () {
        this.getProfile()
      })
    },
  }
  </script>
  