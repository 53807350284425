import {isEmpty, isEqual, omit, pick} from 'lodash';

export default {
  data() {
    return {
      isLoading: false,
      apiValidationErrors: {}
    };
  },
  methods: {
    /* extract API server validation errors and assigns them to local mixin data */
    setApiValidation(serverErrors, refs = null) {
      this.apiValidationErrors = serverErrors.reduce(
        (accumulator, errorObject) => {
          if(typeof errorObject.detail === 'undefined')
            return false;

          const errorFieldName = errorObject.field;
          const errorDetail = errorObject.detail;
          const errorCode = errorObject.code;

          return {
            ...accumulator,
            [errorFieldName]: {
              'detail': errorDetail,
              'code': errorCode
            }
          };
        },
        {}
      );
    },

    resetApiValidation() {
      this.apiValidationErrors = {};
    }
  }
};
