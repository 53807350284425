<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Importar notas de corretagem</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item" @dragover.prevent @drop.prevent>
        <md-card>
          <md-card-content>
            <div>
              <h6>Atenção</h6>
              <ul>
                <li>
                  A nota de corretagem deve estar no formato PDF e no padrão da B3 (Sinacor). É possível que o sistema consiga importar em outro padrão, porém,
                  esse é o padrão homologado.
                </li>
                <li>Podem ser importadas várias notas em um único arquivo PDF ou vários arquivos PDF e de diferentes mercados.</li>
                <li>
                  O sistema reconhece a última versão da nota de corretagem das corretoras, portando, se você já possui uma nota antiga, e o sistema não
                  reconhecer, você pode tentar baixar novamente a nota na correta e realizar uma nova importação.
                </li>
                <li>
                  Arquivos PDF protegidos com senha também podem ser importados. Ao carregar um arquivo com senha, uma nova janela irá abrir para que a senha
                  possa ser informada.
                </li>
                <li>Se não for possível ler a sua nota de corretagem, favor entrar em contato para que possamos avaliar a sua nota.</li>
                <li>
                  Caso a sua corretora não desconte o ISS no total líquido da nota do mercado futuro/BM&amp;F, configure o plano padrão da corretora no menu
                  Configurar Conta.
                </li>
              </ul>
            </div>
            <div @drop="dragFile">
              <md-empty-state md-icon="cloud_upload" md-label="Arraste e solte o(s) arquivo(s) aqui." md-description="OU">
                <input style="display: none" id="multiFiles" name="files[]" type="file" multiple @change="uploadFile" accept="application/pdf" />
                <md-button style="" class="md-primary md-raised" @click="OpenInputFile">Selecionar...</md-button>
                <span v-if="Files.length"
                  >{{ uploadedFiles.length }} / {{ Files.length }} Arquivos carregados - <md-icon id="uploadStatus">pending</md-icon></span
                >
                <div v-if="FilesError.length">
                  <ul v-for="(file, index) in FilesError" :key="index">
                    <li>
                      <span>{{ file.name }} - <md-icon :id="'file_' + index">error</md-icon></span>
                    </li>
                  </ul>
                </div>
              </md-empty-state>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
  data: () => ({
    Files: [],
    uploadedFiles: [],
    FilesError: [],
  }),
  methods: {
    loadImages(files) {
      this.Files = []
      this.uploadedFiles = []
      this.FilesError = []
      Swal.fire({
        title: 'Por favor aguarde',
        html: 'Estamos importando os arquivos',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
        },
      })
      for (var i = 0; i <= files.length - 1; i++) {
        var file = files[i]
        this.Files.push(file)
        if (((file.size / 1024) / 1024) > 12){
          this.$store.dispatch('alerts/error', 'Arquivo ' + file.name + ' atingiu o limite máximo do arquivo ( 12Mb )');
          this.FilesError.push(file)
          if (this.FilesError.length + this.uploadedFiles.length == this.Files.length) {
            $('#uploadStatus').html('done')
            Swal.close()
          }
        }else{
          this.SendFile(file, i)
        }
      }
    },
    uploadFile(e) {
      this.loadImages(e.target.files)
    },
    dragFile(e) {
      this.loadImages(e.dataTransfer.files)
    },
    OpenInputFile() {
      $('input[type=file]').trigger('click')
    },
    SendFile(file, file_index) {
      var this_ori = this
      var url = process.env.VUE_APP_API_BASE_URL + '/upload/upload'
      var form_data = new FormData()
      form_data.append('file', file)

      $.ajaxSetup({
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.token,
        },
      })
      $('#file_' + file_index).html('pending')
      $.ajax({
        url: url, // point to server-side URL
        dataType: 'json', // what to expect back from server
        cache: false,
        contentType: false,
        processData: false,
        data: form_data,
        type: 'post',
        success: function (response) {
          // display success response
          this_ori.uploadedFiles.push(file)
          if (this_ori.FilesError.length + this_ori.uploadedFiles.length == this_ori.Files.length) {
            $('#uploadStatus').html('done')
            this_ori.$store.dispatch('alerts/success', 'Arquivos enviados ')
            Swal.close()
          }
        },
        error: function (response) {
          this_ori.$store.dispatch('alerts/error', 'Erro ao enviar arquivo ' + file.name)
          this_ori.FilesError.push(file)
          if (this_ori.FilesError.length + this_ori.uploadedFiles.length == this_ori.Files.length) {
            $('#uploadStatus').html('done')
            Swal.close()
          }
        },
      })
    },
  },
}
</script>
<style>
#drop_zone {
  border: 5px solid blue;
  width: 200px;
  height: 100px;
}
.md-empty-state {
  max-width: 100% !important;
}
</style>