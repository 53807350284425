<template>
  <footer class="footer">
    <div class="container">
      <!-- <div class="copyright text-center">
        Apure Bolsa, &copy; {{ new Date().getFullYear() }}. Todos os direitos
        reservados.
      </div> -->
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
