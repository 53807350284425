<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Notas importadas</h3>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <md-card>
          <md-card-content>
            <div class="md-tab-filter">
              <span>Data inicial: </span><md-datepicker style="width:fit-content;" v-model="initialDate" md-immediately />
              <span>Data final: </span><md-datepicker style="width:fit-content;" v-model="finalDate" md-immediately />
              <span style="margin-left: 2%;"></span>
              <md-button v-if="!paginationIsLoading" class="md-raised md-warning md-lg" @click="filterNotes">
                <span>Filtrar</span>
              </md-button>
              <md-button v-else class="md-raised md-warning md-lg" disabled>
                <span>Filtrando...</span>
              </md-button>
            </div>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-content>
            <md-table v-if="isNotesLoaded" v-model="notes" md-card md-sort="note_date" md-sort-order="desc">
              <md-table-toolbar>
                <h1 class="md-title">Notas</h1>
              </md-table-toolbar>
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 1">Bovespa | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 2">BM&F | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Mercado | nº nota" v-if="item.market_id == 3">Balcão | {{ item.note_number }}</md-table-cell>
                <md-table-cell md-label="Data da nota">{{ item.note_date | DateFormat }}</md-table-cell>
                <md-table-cell md-label="Corretora">{{ item.stockbroker_object.name }}</md-table-cell>
                <md-table-cell md-label="Importada em">{{ item.brockerage_note_object.created_at | DateTimeFormat }}</md-table-cell>
                <md-table-cell md-label="Nota">{{ item.brockerage_note_object.brockerage_note_file_name }}</md-table-cell>
                <md-table-cell v-if="item.status == 0" md-label="Status">Processando nota</md-table-cell>
                <md-table-cell v-if="item.status == 1" md-label="Status">Aguarando revisão</md-table-cell>
                <md-table-cell v-if="item.status == 2" md-label="Status">Revisando</md-table-cell>
                <md-table-cell v-if="item.status == 3" md-label="Status">Processado com sucesso</md-table-cell>
                <md-table-cell v-if="item.status == 1 || item.status == 2" md-label="ERRO">{{ item.traceback_text }}</md-table-cell>
                <md-table-cell v-else md-label="ERRO"> - </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
    data: () => ({
        profile: null,
        isNotesLoaded: false,
        paginationIsLoading: false,
        notes: [],
        detailNotes: [],
        showDialog: false,
        params: {},
        page: 1,
        defaultPageSize: 100
    }),
    methods: {
      async getNotes(){
        try {
          if(this.profile == null){
            this.profile = await this.$store.dispatch("profile/me");
          }
          if(this.initialDate == null){
            var initialDate = new Date(this.profile.last_brokerage_note);
            initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
            this.initialDate = moment(initialDate).format('DD/MM/YYYY');
            this.finalDate = moment(new Date(this.profile.last_brokerage_note)).format('DD/MM/YYYY');
          }
          this.params = {
            token: this.$store.getters.token,
            page: this.page,
            pageSize: this.defaultPageSize,
            initialDate: moment(this.initialDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
            finalDate: moment(this.finalDate, "DD/MM/YYYY").format("YYYY-MM-DD")
          }
          var notes = null;
          this.paginationIsLoading = true;
          notes = await this.$store.dispatch("profile/getNotes", this.params);
          notes = await notes.items
          console.log(notes);
          this.notes = this.notes.concat(notes.items);
          this.isNotesLoaded = true;
          this.paginationIsLoading = false;
        } catch (error) {
          console.log(error);
        }
      },
      pagnationNotes(){
        this.page++;
        this.getNotes();
      },
      filterNotes(){
        this.isNotesLoaded = false;
        this.page = 1;
        this.notes = [];
        this.paginationIsLoading = true;
        this.getNotes();
        this.paginationIsLoading = false;
      },
      closeDetail(){
        this.detailNotes = [];
        this.showDialog = false;
      }
    },
    beforeMount: function () {
      this.$nextTick(function () {
        this.filterNotes();
      });
    },
};
</script>
<style lang="scss" scoped>
  .md-table + .md-table {
    margin-top: 16px
  }
</style>