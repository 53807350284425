<template>
  <div class="logout-mobile" @click="logout">
    <span class="nav-item nav-link span-nav-mobile"><md-icon>logout</md-icon>Sair</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search: null,
      selectedEmployee: null,
      employees: ['Jim Halpert', 'Dwight Schrute', 'Michael Scott', 'Pam Beesly', 'Angela Martin', 'Kelly Kapoor', 'Ryan Howard', 'Kevin Malone'],
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('profile/resetState', {})
      this.$store.dispatch('logout', {})
    },
  },
}
</script>

<style lang="scss" >
.logout-mobile {
  color: white !important;
  padding: 18px 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}

.span-nav-mobile {
  display: flex;
  align-items: center;
}

@media (min-width: 990px) {
  .logout-mobile {
    display: none;
  }
}
</style>
