<template>
  <div class="wrapper" :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]">
    <notifications></notifications>
    <side-bar :active-color="sidebarBackground" :data-background-color="sidebarBackgroundColor">
      <user-menu></user-menu>
      <template slot="links">
        <sidebar-item :link="{ name: 'Dashboard', icon: 'dashboard', path: '/' }" />

        <!-- <sidebar-item
          :link="{
            name: 'Portifólio',
            icon: 'account_balance_wallet',
            path: '/portifolio',
          }"
        /> -->

        <!-- <sidebar-item
          :link="{
            name: 'Notas de Corretagem',
            icon: 'notes',
            path: '/importar-notas',
          }"
        /> -->
        <sidebar-item :link="{ name: 'Notas de Corretagem', icon: 'notes' }">
          <sidebar-item :link="{ name: 'Importar', path: '/importar-notas' }" />
          <sidebar-item :link="{ name: 'Visualizar notas', path: '/ver-notas' }" />
        </sidebar-item>

        <!-- 
        <sidebar-item :link="{ name: 'Proventos', icon: 'paid' }">
          <sidebar-item
            :link="{
              name: 'Grid de Proventos',
              path: '/proventos',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Eventos', icon: 'grading' }">
          <sidebar-item
            :link="{ name: 'Subscrições', path: '/subscricao' }"
          />
          <sidebar-item
            :link="{
              name: 'Eventos Corporativos',
              path: '/eventos-corporativos',
            }"
          />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Análises', icon: 'trending_up' }">
          <sidebar-item :link="{ name: 'Ativos', path: '/ativos' }" />
          <sidebar-item
            :link="{
              name: 'Fundamentos',
              path: '/fundamentos',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Gráfico',
              path: '/grafico',
            }"
          />
        </sidebar-item> -->

        <sidebar-item :link="{ name: 'Relatórios', icon: 'article' }">
          <sidebar-item :link="{ name: 'Apurações', path: '/apuracoes' }" />
          <sidebar-item :link="{ name: 'Operações', path: '/operacoes' }" />
        </sidebar-item>

        <sidebar-item :link="{ name: 'Indicação', icon: 'group_add', path: '/indicacao' }" />

        <sidebar-item :link="{ name: 'Meus indicados', icon: 'group_add', path: '/meus-indicados' }" />
        <!-- 
        <sidebar-item
          :link="{
            name: 'Central de Ajuda',
            icon: 'question_answer',
            path: '/components/centraldeajuda',
          }"
        />

        <sidebar-item
          :link="{
            name: 'Entrar em Contato',
            icon: 'contact_mail',
            path: '/components/table',
          }"
        /> -->
        <mobile-menu></mobile-menu>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar'
import 'perfect-scrollbar/css/perfect-scrollbar.css'

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`)
    document.getElementsByClassName(className)[0].scrollTop = 0
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className)
    }, 100)
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList
  let isWindows = navigator.platform.startsWith('Win')
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar('sidebar')
    initScrollbar('sidebar-wrapper')
    initScrollbar('main-panel')

    docClasses.add('perfect-scrollbar-on')
  } else {
    docClasses.add('perfect-scrollbar-off')
  }
}

import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import MobileMenu from './Extra/MobileMenu.vue'

import UserMenu from './Extra/UserMenu.vue'

export default {
  components: {
    TopNavbar,
    ContentFooter,
    MobileMenu,
    UserMenu,
  },
  data() {
    return {
      sidebarBackgroundColor: 'black',
      sidebarBackground: 'green',
      sidebarMini: true,
      sidebarImg: true,
      image: 'https://apurebolsa.com.br/assets/images/Apure-bolsa-sistema.jpg', // process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
    }
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
  },
  updated() {
    reinitScrollbar()
  },
  mounted() {
    reinitScrollbar()
    this.minimizeSidebar()
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar()
    },
  },
}
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
