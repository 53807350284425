import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();
const default_result = {
  "totalGain": 0,
  "totalGainPercent": 0,
  "totalItems": 0,
  "sum": 0,
  "items": [],
  "with-error": true
}
var return_result = {};

async function get() {
  return axios.get(`${url}/person/me`)
    .then(response => {
      return response.data
    });
}

async function update(profile) {

  const payload = jsona.serialize({
    stuff: profile,
    includeNames: []
  });

  const options = {
    headers: {
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    }
  };

  return axios.patch(`${url}/register/update/me`, payload, options)
    .then(response => {
      return jsona.deserialize(response.data);
    });
}

function getCalculatedIncomeTax(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  try {
    return axios.get(`${url}/person/calculated-income-tax?page=`+params.page+'&page_size='+params.pageSize+'&min_date='+params.initialDate+'&max_date='+params.finalDate, options)
    .then(response => {
      return_result = response.data;
      return_result['with_error'] = false;
      return return_result;
    });
  } catch (error) {
    return default_result;
  }
}

function getFlexOption(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  try {
    return axios.get(`${url}/person/flex-option?page=`+params.page+'&page_size='+params.pageSize+'&min_date='+params.initialDate+'&max_date='+params.finalDate+'&only_closed='+params.onlyClosed, options)
    .then(response => {
      return_result = response.data;
      return_result['with_error'] = false;
      return return_result;
    });
  } catch (error) {
    return default_result;
  }
}

function getFutureTrade(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  try {
    return axios.get(`${url}/person/future-trade?page=`+params.page+'&page_size='+params.pageSize+'&min_date='+params.initialDate+'&max_date='+params.finalDate+'&only_closed='+params.onlyClosed, options)
    .then(response => {
      return_result = response.data;
      return_result['with_error'] = false;
      return return_result;
    });
  } catch (error) {
    return default_result;
  }
}

function getLastCalculatedIncomeTax(token){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }
  try {
    return axios.get(`${url}/person/last-calculated-income-tax`, options)
    .then(response => {
      return_result = response.data;
      return_result['with_error'] = false;
      return return_result;
    });
  } catch (error) {
    return default_result;
  }
  
}

function getOperations(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  return axios.get(`${url}/person/operations?page=`+params.page+'&page_size='+params.pageSize+'&min_date='+params.initialDate+'&max_date='+params.finalDate+'&only_closed='+params.onlyClosed, options)
    .then(response => {
      try {
        return_result = response.data;
        return_result['with_error'] = false;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

function getPortfolio(token){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + token
    }
  }
  return axios.get(`${url}/person/portfolio`, options)
    .then(response => {
      try {
        return_result = response.data;
        return_result['with_error'] = false;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

function getNotes(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  return axios.get(`${url}/person/brokerage-note?page=`+params.page+'&page_size='+params.pageSize+'&initial_date='+params.initialDate+'&final_date='+params.finalDate+'&only_closed='+params.onlyClosed, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

function getReferral(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    }
  }
  return axios.get(`${url}/person/referral?page=`+params.page+'&page_size='+params.pageSize, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

function getPDF(params){
  const options = {
    headers: {
      'Authorization': 'Bearer ' + params.token
    },
    responseType: 'blob'
  }
  return axios.get(`${url}/person/calculated-income-tax/pdf?month=`+params.month+'&year='+params.year, options)
    .then(response => {
      try {
        return_result = response.data;
        return return_result;
      } catch (error) {
        return default_result;
      }
      
    });
}

export default {
  get,
  update,
  getCalculatedIncomeTax,
  getOperations,
  getPortfolio,
  getLastCalculatedIncomeTax,
  getFlexOption,
  getFutureTrade,
  getNotes,
  getReferral,
  getPDF
};
