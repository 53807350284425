<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Operações</h3>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-tabs-navigation md-elevation-0" style="background-color: #00122a">
          <button type="button" @click="changeTab($event, 'operation')" class="md-button md-tab-nav-button md-theme-default md-active">
            <div class="md-ripple">
              <div class="md-button-content">Operações à vista</div>
            </div>
          </button>
          <button type="button" @click="changeTab($event, 'flexOption')" class="md-button md-tab-nav-button md-theme-default">
            <div class="md-ripple">
              <div class="md-button-content">Operações Balcão</div>
            </div>
          </button>
          <button type="button" @click="changeTab($event, 'futureTrade')" class="md-button md-tab-nav-button md-theme-default">
            <div class="md-ripple">
              <div class="md-button-content">Operações Futuros</div>
            </div>
          </button>
          <span class="md-tabs-indicator md-tabs-indicator-right" style="left: 75px; right: calc(100% - 339px)"></span>
        </div>
        <md-card v-if="selectedTab != 'CalculatedIncomeTax'">
          <md-card-content>
            <div class="md-tab-filter filter-container">
              <div class="field-filter">
                <span>Data inicial: </span>
                <md-datepicker v-model="initialDate" />
              </div>
              <div class="field-filter">
                <span>Data final: </span>
                <md-datepicker v-model="finalDate" />
              </div>

              <span style="margin-left: 2%"></span>
              <md-switch v-model="onlyClosed">Operações finalizadas</md-switch>
              <md-button v-if="!paginationIsLoading" class="md-raised md-warning md-lg" @click="initializeTabs">
                <span>Filtrar</span>
              </md-button>
              <md-button v-else class="md-raised md-warning md-lg" disabled>
                <span>Filtrando...</span>
              </md-button>
            </div>
          </md-card-content>
        </md-card>
        <div class="page-container-section">
          <!-- Operações à Vista -->
          <md-table v-if="isOperationsLoaded && selectedTab == 'operation'" v-model="OperationsPaginated">
            <md-table-toolbar>
              <h1 class="md-title">Operações</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ativo">
                <span style="font-weight: bold">{{ item.stockId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data">
                <span style="font-weight: bold">{{ item.dateFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo">
                <span v-if="item.corporateEventType" style="font-weight: bold">{{ item.corporateEventType }}</span>
                <span v-else-if="item.type == 'buy'" style="font-weight: bold; color: #66bb6a">{{ item.typeFormated }}</span>
                <span v-else-if="item.type == 'sell'" style="font-weight: bold; color: #f44336">{{ item.typeFormated }}</span>
                <span v-else style="font-weight: bold">{{ item.typeFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Quantidade">
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço">
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Emolumentos">
                <span style="font-weight: bold">{{ item.totalFee | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem">
                <span style="font-weight: bold">{{ item.commission | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Total">
                <span style="font-weight: bold">{{ item.total | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro">
                <span v-if="item.profit > 0" style="font-weight: bold; color: #66bb6a">{{ item.profit | CurrencyFormat }}</span>
                <span v-else-if="item.profit < 0" style="font-weight: bold; color: #f44336">{{ item.profit | CurrencyFormat }}</span>
                <span v-else style="font-weight: bold"> -- </span>
              </md-table-cell>
              <md-table-cell md-label="Lucro %">
                <span v-if="item.profitPercent > 0" style="font-weight: bold; color: #66bb6a">{{ item.profitPercent }}%</span>
                <span v-else-if="item.profitPercent < 0" style="font-weight: bold; color: #f44336">{{ item.profitPercent }}%</span>
                <span v-else style="font-weight: bold"> -- </span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <!-- Balcão -->
          <md-table v-if="isFlexOptionLoaded && selectedTab == 'flexOption'" v-model="flexOptionPaginated">
            <md-table-toolbar>
              <h1 class="md-title">Balcão</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Mercado">
                <span style="font-weight: bold">{{ item.flexOptionCommodity.description }}</span>
              </md-table-cell>
              <md-table-cell md-label="Ativo">
                <span style="font-weight: bold">{{ item.flexOption.flexOptionId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Contrato">
                <span style="font-weight: bold">{{ item.contractNumber }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data">
                <span style="font-weight: bold">{{ item.date | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo">
                <span v-if="item.flexOptionCommodity.operationType == 'buy'" style="font-weight: bold; color: #66bb6a">Compra</span>
                <span v-if="item.flexOptionCommodity.operationType == 'sell'" style="font-weight: bold; color: #f44336">Venda</span>
              </md-table-cell>
              <md-table-cell md-label="Quantidade">
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço">
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem">
                <span style="font-weight: bold">{{ item.commission | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tx. Reg.">
                <span style="font-weight: bold">{{ item.registrationFee | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tx Emol.">
                <span style="font-weight: bold">{{ item.fees | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Custo">
                <span style="font-weight: bold">{{ item.cost | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro">
                <span v-if="item.profitLoss > 0" style="font-weight: bold; color: #66bb6a">{{ item.profitLoss | CurrencyFormat }}</span>
                <span v-else-if="item.profitLoss < 0" style="font-weight: bold; color: #f44336">{{ item.profitLoss | CurrencyFormat }}</span>
                <span v-else style="font-weight: bold">{{ item.profitLoss | CurrencyFormat }}</span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <!-- Futuros -->
          <md-table v-if="isFutureTradeLoaded && selectedTab == 'futureTrade'" v-model="futureTradePaginated">
            <md-table-toolbar>
              <h1 class="md-title">Futuros</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Ativo">
                <span style="font-weight: bold">{{ item.futureContract.futureContractId }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretora">
                <span style="font-weight: bold">{{ item.broker.name }}</span>
              </md-table-cell>
              <md-table-cell md-label="Data">
                <span style="font-weight: bold">{{ item.date | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Tipo">
                <span v-if="item.type == 'buy'" style="font-weight: bold; color: #66bb6a">{{ item.typeFormated }}</span>
                <span v-else-if="item.type == 'sell'" style="font-weight: bold; color: #f44336">{{ item.typeFormated }}</span>
                <span v-else style="font-weight: bold">{{ item.typeFormated }}</span>
              </md-table-cell>
              <md-table-cell md-label="Quantidade">
                <span style="font-weight: bold">{{ item.shares }}</span>
              </md-table-cell>
              <md-table-cell md-label="Preço">
                <span style="font-weight: bold">{{ item.price | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Vencimento">
                <span style="font-weight: bold">{{ item.futureContract.dueDate | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Corretagem">
                <span style="font-weight: bold">{{ item.commission | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Emolumentos">
                <span style="font-weight: bold">{{ item.tradingFee | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Total">
                <span style="font-weight: bold">{{ item.cost | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Lucro">
                <span v-if="item.profitLoss > 0" style="font-weight: bold; color: #66bb6a">{{ item.profitLoss | CurrencyFormat }}</span>
                <span v-else-if="item.profitLoss < 0" style="font-weight: bold; color: #f44336">{{ item.profitLoss | CurrencyFormat }}</span>
                <span v-else style="font-weight: bold">{{ item.profitLoss | CurrencyFormat }}</span>
              </md-table-cell>
              <!-- <md-table-cell md-label=""
                ><md-icon>more_vert</md-icon></md-table-cell
              > -->
            </md-table-row>
          </md-table>
          <md-button v-if="selectedTab == 'operation' && !paginationIsLoading" class="md-raised md-success md-lg" @click="getOperations">Ver Mais</md-button>
          <md-button v-if="selectedTab == 'operation' && paginationIsLoading" class="md-raised md-success md-lg" disabled>Carregando...</md-button>
          <md-button v-if="selectedTab == 'flexOption' && !paginationIsLoading" class="md-raised md-success md-lg" @click="getFlexOption">Ver Mais</md-button>
          <md-button v-if="selectedTab == 'flexOption' && paginationIsLoading" class="md-raised md-success md-lg" disabled>Carregando...</md-button>
          <md-button v-if="selectedTab == 'futureTrade' && !paginationIsLoading" class="md-raised md-success md-lg" @click="getFutureTrade">Ver Mais</md-button>
          <md-button v-if="selectedTab == 'futureTrade' && paginationIsLoading" class="md-raised md-success md-lg" disabled>Carregando...</md-button>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import moment from 'moment'
import Swal from 'sweetalert2'
export default {
  data: () => ({
    profile: null,
    params: {},
    OperationsPaginated: [],
    OperationTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    flexOptionPaginated: [],
    flexOptionTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    futureTradePaginated: [],
    futureTradeTotal: {
      sum: 0,
      totalGain: 0,
      totalGainPercent: 0,
      totalItems: 0,
    },
    isOperationsLoaded: false,
    isFlexOptionLoaded: false,
    isFutureTradeLoaded: false,
    paginationIsLoading: true,
    tabsPage: {
      operation: 1,
      flexOption: 1,
      futureTrade: 1,
    },
    defaultPageSize: 100,
    onlyClosed: false,
    selectedTab: 'operation',
    initialDate: null,
    finalDate: null,
  }),
  methods: {
    async initializeTabs() {
      if (this.profile == null) {
        this.profile = await this.$store.dispatch('profile/me')
      }
      if (this.initialDate == null) {
        var initialDate = new Date(this.profile.last_brokerage_note)
        initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1)
        this.initialDate = moment(initialDate).format('L')
        this.finalDate = moment(new Date(this.profile.last_brokerage_note)).format('L')
      }
      this.paginationIsLoading = true
      this.OperationsPaginated = []
      this.flexOptionPaginated = []
      this.futureTradePaginated = []
      for (var key in this.tabsPage) {
        this.tabsPage[key] = 1
      }
      this.params = {
        token: this.$store.getters.token,
        page: 1,
        pageSize: this.defaultPageSize,
        initialDate: moment(this.initialDate).format('YYYY-MM-DD'),
        finalDate: moment(this.finalDate).format('YYYY-MM-DD'),
        onlyClosed: this.onlyClosed,
      }
      this.getOperations()
      this.getFlexOption()
      this.getFutureTrade()
    },
    changeTab(event, selectedTab) {
      $('.md-active').removeClass('md-active')
      $(event.currentTarget).addClass('md-active')
      this.selectedTab = selectedTab
    },
    async getOperations() {
      var Operations = null
      this.paginationIsLoading = true
      this.params['page'] = this.tabsPage['operation']
      Operations = await this.$store.dispatch('profile/getOperations', this.params)
      this.OperationsPaginated = this.OperationsPaginated.concat(Operations.items)
      this.OperationTotal.sum = Operations.sum
      this.OperationTotal.totalGain = Operations.totalGain
      this.OperationTotal.totalGainPercent = Operations.totalGainPercent
      this.OperationTotal.totalItems = Operations.totalItems
      this.paginationIsLoading = false
      this.isOperationsLoaded = true
      this.tabsPage['operation']++
    },
    async getFlexOption() {
      var flexOption = null
      this.paginationIsLoading = true
      this.params['page'] = this.tabsPage['flexOption']
      flexOption = await this.$store.dispatch('profile/getFlexOption', this.params)
      this.flexOptionPaginated = this.flexOptionPaginated.concat(flexOption.items)
      this.paginationIsLoading = false
      this.isFlexOptionLoaded = true
      this.tabsPage['flexOption']++
    },
    async getFutureTrade() {
      var futureTrade = null
      this.paginationIsLoading = true
      this.params['page'] = this.tabsPage['futureTrade']
      futureTrade = await this.$store.dispatch('profile/getFutureTrade', this.params)
      this.futureTradePaginated = this.futureTradePaginated.concat(futureTrade.items)
      this.paginationIsLoading = false
      this.isFutureTradeLoaded = true
      this.tabsPage['futureTrade']++
    },
  },
  beforeMount: function () {
    this.$nextTick(function () {
      this.initializeTabs()
    })
  },
}
</script>
<style lang="scss" scoped>
.field-filter {
  display: flex;
  flex-direction: column;
}

.filter-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}
</style>