<script>
export default {
  data() {
    return {
      isClosed: true,
      title: "Profile",
      avatar: process.env.VUE_APP_BASE_URL + "/img/faces/marc.jpg",
    };
  },

  methods: {
    clicked: function(e) {
      e.preventDefault();
    },
    toggleMenu: function() {
      this.isClosed = !this.isClosed;
    },
    goToProfile() {
      this.$router.push({ name: "User Profile" });
    },
    logout() {
      this.$store.dispatch("profile/resetState", {});
      this.$store.dispatch("logout");
    },
  },
};

</script>
<style>
.collapsed {
  transition: opacity 1s;
}
</style>
