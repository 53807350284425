<template>
  <div class="sidebar" :data-color="activeColor" :data-image="backgroundImage" :data-background-color="backgroundColor" :style="sidebarStyle">
    <div class="logo">
      <a href="/" class="simple-text logo-mini">
        <div style="padding: 4px 0">
          <img src="https://apurebolsa.com.br/assets/images/logoapb.png" height="50" width="50" />
        </div>
      </a>
      <a href="/" class="simple-text logo-normal">
        <template>{{ title }}</template>
      </a>
    </div>
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <md-list class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink"> </sidebar-item>
          </sidebar-item>
        </slot>
      </md-list>
    </div>
  </div>
</template>
<script>
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Apure Bolsa',
    },
    activeColor: {
      type: String,
      default: 'apure',
      validator: (value) => {
        let acceptedValues = ['', 'purple', 'azure', 'green', 'orange', 'danger', 'rose', 'apure']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    backgroundColor: {
      type: String,
      default: '#00193e',
      validator: (value) => {
        let acceptedValues = ['', 'black', 'white', 'red', 'apure']
        return acceptedValues.indexOf(value) !== -1
      },
    },
    logo: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URL}/img/vue-logo.png`,
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$sidebar.toggleMinimize()
  },
  provide() {
    return {
      autoClose: this.autoClose,
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    },
  },
  computed: {
    sidebarStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
      }
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
}
</script>
<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
  .logo {
    background-color: rgb(0, 18, 42) !important;
  }
}
</style>
