<template>
  <div class="md-layout text-center login-fix-page">
    <div class="md-layout-item md-size-100">
      <div class="text-center">
        <h3>
          <img
            src="https://apurebolsa.com.br/assets/images/logoapb.png"
            height="100"
            width="100"
          />
        </h3>
        <p>Foque nos seus investimentos com tranquilidade!</p>
      </div>
      <div class="text-center" style="margin-bottom: 32px"></div>
    </div>

    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
    >
      <form @submit.prevent="login">
        <login-card header-color="apure">
          <h4 slot="title" class="title">Informe suas credenciais</h4>
          <md-field
            class="form-group md-invalid"
            slot="inputs"
            style="margin-bottom: 28px"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input v-model="email" type="email" />
          </md-field>
          <md-field class="form-group md-invalid" slot="inputs">
            <md-icon>lock_outline</md-icon>
            <label>Senha...</label>
            <md-input v-model="password" type="password" />
            <validation-error :error="apiValidationErrors.null" />
          </md-field>
          <md-button
            @click="sendForgotCode"
            slot="footer"
            type="button"
            class="md-simple md-warning md-lg"
          >
            Esqueci senha
          </md-button>
          <md-button
            slot="footer"
            type="submit"
            class="md-simple md-success md-lg"
          >
            Efetuar login
          </md-button>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import formMixin from "@/mixins/form-mixin";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  components: {
    LoginCard,
    ValidationError
  },

  mixins: [formMixin],
  data() {
    return {
      email: null,
      password: null
    };
  },

  methods: {
    async sendForgotCode() {
      this.$router.push({ name: "ForgotPassword" });
    },
    async login() {
      const this_ori = this;
      const user = {
        email: this.email,
        password: this.password,
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        Swal.showLoading();
        await this.$store.dispatch("login", { user, requestOptions });
        Swal.fire({
          icon: "success",
          html: "Aguarde, estamos carregando suas permissões!",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        var referenceDate = new Date();
        const params = {
          token: this.$store.getters.token,
          page: 1,
          pageSize: 100,
          referenceDate: moment(referenceDate).format("YYYY-MM-DD"),
          onlyClosed: true,
        };
        try{
          this_ori.$store.dispatch("profile/getCalculatedIncomeTax", params);
          this_ori.$store.dispatch("profile/getClosedOperations", params);
          this_ori.$store.dispatch("profile/getLastCalculatedIncomeTax", params);
          this_ori.$store.dispatch("profile/getPortfolio", params);
        } catch (e){
          console.log(e);
        }
        setTimeout(function () {
          this_ori.$router.push({ path: "/" });
        }, 2000);
      } catch (e) {
        this_ori.setApiValidation(e.response.data);
        await this_ori.$store.dispatch("alerts/error", "Verifique os dados e tente novamente!");
      } finally {
        setTimeout(function () {
          Swal.close();
        }, 2000);
      }
    },
  },
};
</script>
<style scoped>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}
</style>
