<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Apuração Mensal</h3>
    </div>
    <md-card>
      <md-card-content>
        <div class="md-tabs-navigation md-elevation-0" style="background-color: #00122a">
          <button type="button" @click="changeTab($event, 'CalculatedIncomeTax')" class="md-button md-tab-nav-button md-theme-default md-active">
            <div class="md-ripple">
              <div class="md-button-content">Relatório de Apuração Mensal</div>
            </div>
          </button>
          <span class="md-tabs-indicator md-tabs-indicator-right" style="left: 75px; right: calc(100% - 339px)"></span>
        </div>
        <div class="page-container-section">
          <div class="loading" v-if="!isCalculatedIncomeTaxLoaded"></div>
          <md-table v-else-if="selectedTab == 'CalculatedIncomeTax'" v-model="CalculatedIncomeTaxPaginated">
            <md-table-toolbar>
              <h1 class="md-title">Apurações</h1>
            </md-table-toolbar>
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Mês Ref.">
                <span style="font-weight: bold">{{ item.referenceDate | MonthYearFormatDate }}</span>
              </md-table-cell>
              <md-table-cell md-label="Vencimento">
                <span style="font-weight: bold">{{ item.dueDate | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Pagamento">
                <span style="font-weight: bold">{{ item.payday | DateFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Swing Trade">
                <span v-if="item.totalGainNormal > 0" style="font-weight: bold; color: #66bb6a">{{ item.totalGainNormal | CurrencyFormat }}</span>
                <span v-else-if="item.totalGainNormal < 0" style="font-weight: bold; color: #f44336">{{ item.totalGainNormal | CurrencyFormat }}</span>
                <span v-else style="font-weight: bold">{{ item.totalGainNormal | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Day Trade">
                <span v-if="item.totalGainDayTrade > 0" style="font-weight: bold; color: #66bb6a">{{ item.totalGainDayTrade | CurrencyFormat }}</span>
                <span v-else-if="item.totalGainDayTrade < 0" style="font-weight: bold; color: #f44336">{{ item.totalGainDayTrade | CurrencyFormat }}</span>
                <span v-else style="font-weight: bold">{{ item.totalGainDayTrade | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="FIIs">
                <span v-if="item.fii.totalMonthGainNormal > 0" style="font-weight: bold; color: #66bb6a">{{
                  item.fii.totalMonthGainNormal | CurrencyFormat
                }}</span>
                <span v-else-if="item.fii.totalMonthGainNormal < 0" style="font-weight: bold; color: #f44336">{{
                  item.fii.totalMonthGainNormal | CurrencyFormat
                }}</span>
                <span v-else style="font-weight: bold">{{ item.fii.totalMonthGainNormal | CurrencyFormat }}</span>
              </md-table-cell>
              <md-table-cell md-label="Imposto">
                <span v-if="item.totalTaxNormalAndTaxDayTrade > 0" style="font-weight: bold; color: #f44336">{{
                  item.totalTaxNormalAndTaxDayTrade | CurrencyFormat
                }}</span>
                <span v-else style="font-weight: bold"> -- </span>
              </md-table-cell>
              <md-table-cell md-label="DARF">
                <md-button v-if="item.totalTaxNormalAndTaxDayTrade > 10" style="width: 200px" @click="calculatedIncomeTaxDarf = item" class="md-warning"
                  ><span>Gerar Darf</span></md-button
                >
                <span v-else></span>
              </md-table-cell>
              <md-table-cell md-label="">
                <a style="cursor: pointer" @click="downloadPDF(item.referenceDate)"><md-icon>print</md-icon></a>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <md-button v-if="selectedTab == 'CalculatedIncomeTax' && !paginationIsLoading" class="md-raised md-success md-lg" @click="getCalculatedIncomeTax"
            >Ver Mais</md-button
          >
          <md-button v-if="selectedTab == 'CalculatedIncomeTax' && paginationIsLoading" class="md-raised md-success md-lg" disabled>Carregando...</md-button>
        </div>
      </md-card-content>
    </md-card>
    <modal-darf v-if="calculatedIncomeTaxDarf" :data="calculatedIncomeTaxDarf" :user="profile" :handleClose="handleCloseModal"></modal-darf>
  </div>
</template>
<script>
import moment from 'moment'
import { ModalDarf } from '@/components'
import Swal from 'sweetalert2'
export default {
  components: {
    ModalDarf,
  },
  data: () => ({
    profile: null,
    params: {},
    calculatedIncomeTaxDarf: null,
    showModalDarf: false,
    CalculatedIncomeTaxPaginated: [],
    paginationIsLoading: true,
    tabsPage: {
      CalculatedIncomeTax: 1,
    },
    defaultPageSize: 100,
    onlyClosed: false,
    selectedTab: 'CalculatedIncomeTax',
    initialDate: null,
    finalDate: null,
  }),
  methods: {
    async initializeTabs() {
      if (this.profile == null) {
        this.profile = await this.$store.dispatch('profile/me')
      }
      if (this.initialDate == null) {
        var initialDate = new Date(this.profile.last_brokerage_note)
        initialDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1)
        this.initialDate = moment(initialDate).format('L')
        this.finalDate = moment(new Date(this.profile.last_brokerage_note)).format('L')
      }
      this.paginationIsLoading = true
      for (var key in this.tabsPage) {
        this.tabsPage[key] = 1
      }
      this.params = {
        token: this.$store.getters.token,
        page: 1,
        pageSize: this.defaultPageSize,
        initialDate: moment(this.initialDate).format('YYYY-MM-DD'),
        finalDate: moment(this.finalDate).format('YYYY-MM-DD'),
        onlyClosed: this.onlyClosed,
      }
      if (!this.isCalculatedIncomeTaxLoaded) {
        this.getCalculatedIncomeTax()
      }
    },
    changeTab(event, selectedTab) {
      $('.md-active').removeClass('md-active')
      $(event.currentTarget).addClass('md-active')
      this.selectedTab = selectedTab
    },
    async getCalculatedIncomeTax() {
      try {
        var calculatedIncomeTaxItem = null
        this.paginationIsLoading = true
        this.params['page'] = this.tabsPage['CalculatedIncomeTax']
        calculatedIncomeTaxItem = await this.$store.dispatch('profile/getCalculatedIncomeTax', this.params)
        this.CalculatedIncomeTaxPaginated = this.CalculatedIncomeTaxPaginated.concat(calculatedIncomeTaxItem.items)
        this.paginationIsLoading = false
        this.isCalculatedIncomeTaxLoaded = true
        this.tabsPage['CalculatedIncomeTax']++
      } catch (error) {
        console.log(error)
      }
    },
    showDarf(calculatedIncomeTaxDarf) {
      this.calculatedIncomeTaxDarf = calculatedIncomeTaxDarf
      this.showModalDarf = true
    },
    async handleCloseModal() {
      this.calculatedIncomeTaxDarf = null;
      document.title = "Apure Bolsa"
    },
    
    async downloadPDF(selectedDate) {
      try {
          Swal.fire({
          title: 'Por favor aguarde',
          html: 'Estamos emitindo seu relatório',
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
          },
        })
        var responsePdf;
        var referenceDate = new Date(selectedDate);
        this.params = {
          token: this.$store.getters.token,
          month: referenceDate.getMonth() + 1,
          year: referenceDate.getFullYear()
        }
        responsePdf = await this.$store.dispatch('profile/getPDF', this.params);
        let blob = new Blob([responsePdf], { type:"application/pdf" })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'Apuracao '+this.params.month+' - '+this.params.year+'.pdf'
        link.click();
      } catch (e) {
        await this.$store.dispatch('alerts/error', 'Erro ao efetuar download.')
        console.error(e)
      } finally {
        Swal.close()
      }
    }
  },
  beforeMount: function () {
    this.$nextTick(function () {
      this.initializeTabs()
    })
  },
}
</script>
<style lang="scss" scoped>
.field-filter {
  display: flex;
  flex-direction: column;
}

.filter-container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}
</style>