import qs from 'qs';
import axios from 'axios';
import Jsona from 'jsona';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function list(params) {
  const options = {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, {encode: false});
    }
  };

  return axios.get(`${url}/users`, options)
    .then(response => {
      return {
        list: jsona.deserialize(response.data),
        meta: response.data.meta
      };
    });
}

function upload(user, image) {
  const bodyFormData = new FormData();
  bodyFormData.append('attachment', image);

  return axios.post(`${url}/uploads/users/${user.id}/profile-image`, bodyFormData)
    .then(response => {
      return response.data.url;
    });
}

function uploadMulti(files, token) {
    const bodyFormData = new FormData();
    bodyFormData.append('files', files);
    const options = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    };
    return axios.post(`${url}/upload/upload-many`, bodyFormData, options)
      .then(response => {
        return response.data.url;
      });
}

export default {
  list,
  upload,
  uploadMulti
};

