import service from '@/store/services/payments-service';

const state = {};

const mutations = {}

const actions = {
    sendOrder({commit, dispatch}, params){
        return service.sendOrder(params);
    },
    sendPlanOrder({commit, dispatch}, params){
        return service.sendPlanOrder(params);
    },
    getOrder({commit, dispatch}, params){
        return service.getOrder(params);
    },
    getPendingOrder({commit, dispatch}, params){
        return service.getPendingOrder(params);
    }
}

const payments = {
    namespaced: true,
    state,
    actions,
    mutations
};

export default payments;